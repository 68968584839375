.slide {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none; }
  .slide:focus {
    outline: none !important; }
  .slideHorizontal {
    float: left; }
    [dir='rtl'] .slideHorizontal {
      direction: rtl;
      transform: scaleX(-1); }
  .slideInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.focusRing {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight; }
  @media (-webkit-min-device-pixel-ratio: 0) {
    .focusRing {
      outline-style: auto;
      outline-color: -webkit-focus-ring-color; } }
