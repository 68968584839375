.container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: zoom-in;
  transition: opacity 300ms, transform 300ms; }

.hover {
  opacity: 1; }

.zoom {
  opacity: 1; }

.loading {
  opacity: 1; }

.imageLoadingSpinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4; }
